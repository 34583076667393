










































































import { Component, Prop, Vue, Ref, Watch } from "vue-property-decorator";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { districts, areas } from "@/assets/constant";
import i18n from "@/i18n";

import App from "@/App.vue";
import AddressPage from "./Address.vue";



// The MDC Components
import TheMDCTextFieldOutlined from "@/components/mdcComponents/textFields/TheMDCTextFieldOutlined.vue";
import TheMDCIconButton from "@/components/mdcComponents/buttons/TheMDCIconButton.vue";
import { MDCList } from "@material/list";
import { MDCRipple } from "@material/ripple";


import TheMDCSelectOutlined from "@/components/mdcComponents/selects/TheMDCSelectOutlined.vue";
import TheMDCButtonShapedRaised from "@/components/mdcComponents/buttons/TheMDCButtonShapedRaised.vue";
import TheMDCButtonShaped from "@/components/mdcComponents/buttons/TheMDCButtonShaped.vue";
import TheMDCTextArea from "@/components/mdcComponents/textFields/TheMDCTextArea.vue";
import { ShipmentHelpers } from "@/utils/shipmentHelpers.ts";
import TheMDCCircularProgress from "@/components/mdcComponents/progressIndicators/TheMDCCircularProgress.vue";





@Component({
  components: {
    TheMDCTextFieldOutlined,
    TheMDCIconButton,

    TheMDCSelectOutlined,
    TheMDCButtonShapedRaised,
    TheMDCButtonShaped,
    TheMDCTextArea,
    TheMDCCircularProgress
  }
})
export default class BuildingSearch extends Vue implements VuePromptCaller {  // Visiter Pattern
  public on(
    event: "accept" | "close",
    payload?: any,
    execution?: CallBack<void> | null
  ) {
    if (event === "accept") {
      if (execution) {
        execution(event, payload);
      }
    }
  }
  private thisList!: MDCList;

  private ROOT = this.$root.$children[0] as App;

  keyword = "";
  searchResultsMenuList = [] as MenuItem[];
  searching=true;

  @Ref("keyword") keywordComponent!: TheMDCTextFieldOutlined;

  @Watch("keyword")
  onKeywordUpdate(newVal: string, oldVal: string){
    this.keywordSearchingTimeout(newVal);
  }
  

  //using variable because select can't set value after dynamically append options.
  //static variable of area menu array need to be set before the area select component is rendered
  beforeCreate() {
    this.$store.commit("hideNavBar");
    this.$store.commit("hideTitle");
  }

  mounted(){
    this.thisList = new MDCList(
      document.querySelector(
        "#building-search-results-container .mdc-list"
      ) as HTMLElement
    );
    //get from Address.vue(parent) property
    this.setKeywordInput((this.$parent as AddressPage).buildingAddress);
    // this.keywordSearching(this.getKeywordFromBuildingAddress((this.$parent as AddressPage).buildingAddress as BuildingAddress));
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      this.keywordComponent.focus();
      this.$store.commit("hideLoading");
    });
  }


  get addressMutation(){
    return this.$parent.$props.addressMutation;
  }

  get title(){
    return this.$parent.$props.title;
  }

  get savedAddressGetter(){
    return this.$parent.$props.savedAddressGetter;
  }

  get completingRedirectName(){
    return this.$parent.$props.completingRedirectName;
  }

  private getKeywordFromBuildingAddress(buildingAddress: BuildingAddress | null): string{
    if(buildingAddress){
      return (this.$i18n.locale === "cant"?(buildingAddress.fullAddressChi || buildingAddress.buildingChi || buildingAddress.streetChi):(buildingAddress.fullAddressEn || buildingAddress.buildingEn || buildingAddress.streetEn)) as string;
    }else{
      return "";
    }
  }

  

  private async backToAddressForm(){
    await this.$router.push({
      name: "ExpressOrderAddress",
      params: { addressMutation: this.addressMutation, title: this.title, savedAddressGetter: this.savedAddressGetter}
    });
  }

   timeoutId: any = null;
  private async keywordSearchingTimeout(keyword: string){
    this.searching=true;
    if(this.timeoutId){
      clearTimeout(this.timeoutId as any);
    }
    if(keyword && keyword.length >= 2){
      this.timeoutId = await window.setTimeout( async () => this.keywordSearching(keyword), Number(process.env.VUE_APP_BUILDING_SEARCH_TIMEOUT as string));
    }else{
      this.resetSearchResults();
      this.searching=false;
    }
  }

  private async keywordSearching(keyword: string){
    this.searching=true;
    if(keyword && keyword.length >= 2){
      if(await this.$store.dispatch("address/fetchBuildingSearchResults",keyword) === true){
        this.updateSearchResults();
      }else{
        //TODO: handling search error
        this.resetSearchResults();
      }
    }else{
      this.resetSearchResults();
    }
    this.searching=false;
  }

  private updateSearchResults(){
    this.searchResultsMenuList = [];
    const searchResults = this.$store.getters["address/getBuildingSearchResults"];
    if(searchResults){
      this.searchResultsMenuList = searchResults.slice().map((result: BuildingAddress, index: string) => {
        return {
          key: index,
          value: result.id,
          expression: [this.$i18n.locale === "cant"?result.fullAddressChi:result.fullAddressEn,(!result.canBeDelivered?this.$t('general.leftBracket') as string + this.$t('shipment.shipmentForm.addressForm.serviceNotAvailable') + this.$t('general.rightBracket') as string:"")].join(""),
          additionData: {canBeDelivered: result.canBeDelivered}
        } as MenuItem
      });
    }
    //free input option
    if(this.keywordComponent){
      this.searchResultsMenuList.push({
        key: (searchResults?searchResults.length :0),
        value: "",
        expression: this.$t('shipment.shipmentForm.addressForm.buildingSearch.freeInputText') as string + " '" + this.keywordComponent.getValue() + "' "
      } as MenuItem);
      this.keywordComponent.focus();
    }
    
  }

  private resetSearchResults(){
    this.searchResultsMenuList = [];
    this.searching = false;
    this.keywordComponent.focus();

  }
  private setKeywordInput(buildingAddress: BuildingAddress | null = null){
    if(buildingAddress){
      const keywordFromAddress = this.getKeywordFromBuildingAddress(buildingAddress);
      this.keywordComponent.setValue(keywordFromAddress || "");
      this.keyword = keywordFromAddress || "";
      
    }else{
      this.resetSearchResults();
    }

  }

  private async selectSearchingResult(resultId: string){
    let buildingAddress = null;
    if(resultId){
      buildingAddress = this.$store.getters["address/getBuildingSearchResultById"](resultId);
      if((this.$parent as AddressPage).address){
        ((this.$parent as AddressPage).address as ExpressOrderAddress).areaRank = buildingAddress.areaRank;  
      }
    }else{
      buildingAddress = {
        id: "",
        fullAddressEn: this.keywordComponent.getValue().trim(),
        fullAddressChi: this.keywordComponent.getValue().trim(),
        areaRank: "",
        isFreeInput: true
      }
      if((this.$parent as AddressPage).address){
        ((this.$parent as AddressPage).address as ExpressOrderAddress).areaRank = "";
      }
    }
    (this.$parent as AddressPage).buildingAddress = buildingAddress;
    await this.$router.push({
      name: "ExpressOrderAddress",
      params: { 
        addressMutation: this.addressMutation, 
        title: this.title, 
        savedAddressGetter: this.savedAddressGetter, 
        addressId: this.savedAddressGetter, 
        completingRedirectName: this.completingRedirectName 
      }
    });
  }
}

