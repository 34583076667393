var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mdc-layout-grid building-search__container" },
    [
      _c(
        "div",
        { staticClass: "building-search__inner mdc-layout-grid__inner" },
        [
          _c(
            "div",
            {
              staticClass:
                "building-search-title__container mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__inner"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "mdc-layout-grid__inner building-search-title__inner"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "back-button-container mdc-layout-grid__cell--span-1-phone mdc-layout-grid__cell--span-2-tablet mdc-layout-grid__cell--span-3-desktop"
                    },
                    [
                      _c("TheMDCIconButton", {
                        attrs: {
                          reference: "back-button",
                          icon: "arrow_back_ios",
                          event: "back-to-address-form"
                        },
                        on: { "back-to-address-form": _vm.backToAddressForm }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mdc-layout-grid__cell--span-2-phone mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-6-desktop"
                    },
                    [
                      _c("h3", { staticClass: "building-search-title" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "shipment.shipmentForm.addressForm.buildingSearch.title"
                              )
                            ) +
                            "\n        "
                        )
                      ])
                    ]
                  ),
                  _c("div", {
                    staticClass:
                      "mdc-layout-grid__cell--span-1-phone mdc-layout-grid__cell--span-2-tablet mdc-layout-grid__cell--span-3-desktop"
                  })
                ]
              )
            ]
          ),
          _c("div", {
            staticClass:
              "building-search-input__container mdc-layout-grid__cell--span-1-phone mdc-layout-grid__cell--span-2-tablet mdc-layout-grid__cell--span-3-desktop "
          }),
          _c(
            "div",
            {
              staticClass:
                "building-search-input__container mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop "
            },
            [
              _c(
                "div",
                { staticClass: "building-search-container" },
                [
                  _c("TheMDCTextFieldOutlined", {
                    ref: "keyword",
                    attrs: {
                      reference: "keyword",
                      label: _vm.$t(
                        "shipment.shipmentForm.addressForm.buildingSearch.buildingAddress"
                      ),
                      type: "text",
                      isRequired: true,
                      maxlength: 70,
                      autofocus: true
                    },
                    model: {
                      value: _vm.keyword,
                      callback: function($$v) {
                        _vm.keyword = $$v
                      },
                      expression: "keyword"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: " search-button-container " },
                [
                  _c("TheMDCIconButton", {
                    attrs: {
                      reference: "search-button",
                      icon: "search",
                      event: "click-search-icon-button"
                    },
                    on: {
                      "click-search-icon-button": function($event) {
                        _vm.keywordSearching(_vm.keywordComponent.getValue())
                      }
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "overflow--scroll building-search-result__container mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop",
              attrs: { id: "building-search-results-container" }
            },
            [
              _c("TheMDCCircularProgress", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.searching,
                    expression: "searching"
                  }
                ],
                staticClass: "search-loading",
                attrs: { reference: "searchLoading" }
              }),
              _c(
                "ul",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.searching,
                      expression: "!searching"
                    }
                  ],
                  staticClass: "mdc-list"
                },
                _vm._l(_vm.searchResultsMenuList, function(
                  addressResult,
                  index
                ) {
                  return _c(
                    "li",
                    {
                      key: addressResult.key,
                      staticClass: "building-search-result mdc-list-item",
                      class: {
                        "mdc-list-item--disabled":
                          addressResult &&
                          addressResult.additionData &&
                          !addressResult.additionData.canBeDelivered
                      },
                      attrs: {
                        tabindex: index,
                        disabled:
                          addressResult &&
                          addressResult.additionData &&
                          !addressResult.additionData.canBeDelivered,
                        value: addressResult.value
                      },
                      on: {
                        click: function($event) {
                          return _vm.selectSearchingResult(addressResult.value)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(addressResult.expression) +
                          "\n        "
                      )
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }